@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply m-0 font-sans antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    @apply font-mono;
  }
}
